















import Vue from 'vue'
import { IUtilisateur } from '../store/types'

import axios from 'axios'
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export default Vue.extend({
  name: 'Login',
  data() {
    return {
      unsubscribe: null as any,
      identifiant: '',
      motdepasse: '',
      salaries: this.$store.state.salaries,
      utilisateurs_options: [],
      uuid: localStorage.getItem('maintenance-uuid')
    }
  },
  methods: {
    connexion(historique: boolean) {
      axios
        .post(this.$store.state.ajaxurl + 'login.php', {
          action: 'LOGIN',
          version: this.$store.state.version,
          user: this.identifiant,
          password: this.motdepasse,
          uuid: this.uuid
        })
        .then((response) => {
          if (response && response.data.authentification === 'oui') {
            localStorage.setItem('maintenance_identifiant', this.identifiant)
            localStorage.setItem('maintenance_motdepasse', this.motdepasse)
            localStorage.setItem('maintenance_utilisateur', response.data.user)
            this.$store.commit('login', this.identifiant)
            this.$router.push('/maintenance')
          }
        })
    },
    ajouter(n: string) {
      this.motdepasse += n
      // if (this.motdepasse.length === 6) this.connexion(true)
    }
  },
  created() {
    if (!localStorage.getItem('maintenance-uuid')) {
      this.uuid = uuidv4()
      localStorage.setItem('maintenance-uuid', this.uuid)
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'majSalaries':
          this.salaries = this.$store.state.salaries.slice()
          this.utilisateurs_options = this.salaries.map((U: IUtilisateur) => {
            return { label: U.ut_prenom + ' ' + U.ut_nom, value: U.ut_id }
          })
          break
      }
    })
    this.$store.dispatch('chargerTables')
  },
  beforeDestroy() {
    this.unsubscribe()
  }
})
